import React from "react"


import Layout from '../components/layout'
import Head from '../components/head'
import AllPosts from '../components/analysis_posts'
import FeaturedPosts from '../components/featured_posts'

import pageTitleStyles from '../components/page_titles.module.scss'

const analysis = () => {
    return (
        <Layout>
            <Head title="Analysis" pageDescription="Data driven NFL research and insights"/>
            <h1 className={pageTitleStyles.title_h2}>Analysis</h1>
            <h2 className={pageTitleStyles.subHeader}>A summary of all the research and insights that go into the models found on the nfelo app</h2> 
            <FeaturedPosts />
            <AllPosts />
        </Layout>
    )
}

export default analysis

